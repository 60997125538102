import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xRPL3DAPES extends Component {
  render() {
    return (
      <CustomTable
        tokenId="3344415045530000000000000000000000000000"
        helmetPageTitle="3DAPES Richlist"
        helmetPageDescription="Richlist for the 3DAPES coin on XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/X3DAPES.png" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/X3DAPES.png"
              alt="3DAPES logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            3DAPES Richlist
          </span>
        }
        introText={
          <span>
            The first 3D Apes on the XRPL,{" "}
            <a href="https://xrpl3dapes.com/">get one</a>.
          </span>
        }
        logoImage={
          <a href="https://xrpl3dapes.com/" className="no-hover">
            <img
              src="/img/X3DAPES_BANNER.jpg"
              alt="3DAPES banner of clubhouse"
              className="x3dapes-banner"></img>
          </a>
        }
        tableAriaLabel="3DAPES holders"
      />
    );
  }
}

export default xRPL3DAPES;
