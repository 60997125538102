import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xWAR extends Component {
  render() {
    return (
      <CustomTable
        tokenId="5857415200000000000000000000000000000000"
        helmetPageTitle="XWAR Richlist"
        helmetPageDescription="Richlist for XWAR on the XRPL"
        helmetLogoImage={<link rel="icon" href="/img/XWAR.jpg" sizes="32x32" />}
        introHeading={
          <span>
            <img
              src="/img/XWAR.jpg"
              alt="XWAR logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            XWAR Richlist
          </span>
        }
        introText={
          <span>
            Play2Earn first-person metaverse shooter,{" "}
            <a href="https://linktr.ee/metaweapon">check it out</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="XWAR holders"
      />
    );
  }
}

export default xWAR;
