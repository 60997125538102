import React, { Component } from "react";
import CustomNFTTable from "../../organisms/CustomNFTTable/CustomNFTTable.js";

class nxPIZZA extends Component {
  render() {
    return (
      <CustomNFTTable
        tokenId="7850697A7A610000000000000000000000000000"
        helmetPageTitle="xPizza NFT Richlist"
        helmetPageDescription="Richlist for xPizza NFT on the XRPL"
        introHeading={
          <span>
            <span role="img" aria-label="pizza slice emoji">
              🍕
            </span>{" "}
            xPizza NFT Richlist
          </span>
        }
        introText={<span>xPizza served up hot and fresh.</span>}
        logoImage=""
        tableAriaLabel="xPizza NFT holders"
        sortType="2"
        nftColName="Owned"
        isOnXRP={true}
        nftIssuer="rPmKjm8E4nXJbXiH8PVtBPcRHP8UJeZWhQ"
      />
    );
  }
}

export default nxPIZZA;
