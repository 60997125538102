import React, { Component } from "react";
import CustomNFTTable from "../../organisms/CustomNFTTable/CustomNFTTable.js";

class nxRDOGE extends Component {
  render() {
    return (
      <CustomNFTTable
        tokenId="5852646F67650000000000000000000000000000"
        helmetPageTitle="XRdoge NFT Richlist"
        helmetPageDescription="Richlist for XRdoge NFTs on the XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/XRdoge.png" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/XRdoge.png"
              alt="XRdoge logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            XRdoge NFT Richlist
          </span>
        }
        introText={
          <span>
            Make sure you have an{" "}
            <a href="https://linktr.ee/xrdoge.lab">XRdoge</a> trustline set for
            your wallet to show in the list. <br /> Check out the{" "}
            <a href="/token/xrdoge">token richlist here</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="XRdoge NFT holders"
        sortType="2"
        nftColName="Points"
        walletLink="https://sologenic.org/profile/"
        isOnXRP={false}
      />
    );
  }
}

export default nxRDOGE;
