import React, { Component } from "react";
import CustomNFTTable from "../../organisms/CustomNFTTable/CustomNFTTable.js";

class nxPRYMALZ extends Component {
  render() {
    return (
      <CustomNFTTable
        tokenId="7852594F00000000000000000000000000000000"
        helmetPageTitle="xPrymalz NFT Richlist"
        helmetPageDescription="Richlist for xPrymalz NFTs on the XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/xPrymalz.jpg" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/xPrymalz.jpg"
              alt="xPrymalz logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            xPrymalz NFT Richlist
          </span>
        }
        introText={
          <span>
            Make sure you have an{" "}
            <a href="https://xrpl.services/?issuer=rLPZJ6xQkq36DurkpqsQkggMpMktPVUrVD&currency=xRYO&limit=1000000000">
              xRYO
            </a>{" "}
            trustline set for your wallet to show in the list. <br /> Check out
            the <a href="/token/xryo">token richlist here</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="xPrymalz NFT holders"
        sortType="2"
        nftColName="Owned"
        walletLink="https://sologenic.org/profile/"
        isOnXRP={false}
      />
    );
  }
}

export default nxPRYMALZ;
