import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xSTIK extends Component {
  render() {
    return (
      <CustomTable
        tokenId="785354494B000000000000000000000000000000"
        helmetPageTitle="xSTIK Richlist"
        helmetPageDescription="Richlist for the xSTIK coin on XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/xstik-logo.jpeg" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/xstik-logo_192x192.jpeg"
              alt="xSTIK logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            xSTIK Richlist
          </span>
        }
        introText={
          this.props.introTextOverride ? (
            this.props.introTextOverride
          ) : (
            <span>
              xSTIK to the moon,{" "}
              <a href="https://www.xrpstik.com/">join them</a>.
            </span>
          )
        }
        logoImage={this.props.logoImageOverride}
        tableAriaLabel="xSTICK Holders"
      />
    );
  }
}

export default xSTIK;
