import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import NumberFormat from "react-number-format";
import isEmbed from "../../../utils/isEmbed.js";

// The custom search bar
const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar>
      <TextField
        label="Search for your wallet"
        margin="normal"
        fullWidth
        id="search"
        type="search"
        placeholder="Search for your wallet"
        onChange={props.handleSearch}></TextField>
    </Toolbar>
  );
};

// Style the table heading
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#15202b",
    color: "#fff",
  },
}));

// Style the table odd rows
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#a9c0d6",
  },
}));

// The custom pagination bar
function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleOnInputPageChange = (event) => {
    let pageToBe = event.target.value - 1;
    // min is 0
    pageToBe = pageToBe < 1 ? 0 : pageToBe;

    // max is max
    let max = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
    pageToBe = pageToBe > max ? max : pageToBe;
    onPageChange(event, pageToBe);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <div className="table-page-container">
        <p className="table-page-label">Jump to page:</p>
        <input
          className="table-page-input"
          type="text"
          onChange={handleOnInputPageChange}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </div>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

class CustomTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      isNFTProject: false,
      isEmpty: false,
      items: [],
      page: 0,
      rowsPerPage: 100,
      knownWallets: 0,
      searchTerm: "",
      searchPage: "",
      sortType: this.props.sortType ?? "0",
      lastPriceInXrp: -1,
    };
  }

  getDataFromApi() {
    let { page, rowsPerPage, searchTerm, sortType } = this.state;

    // FE is zero indexed
    let bePage = page + 1;

    fetch(
      `https://love.richlist.xyz/api/getpaged?token=${this.props.tokenId}&page=${bePage}&take=${rowsPerPage}&term=${searchTerm}&sortType=${sortType}`,
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return this.setState({
            isLoaded: true,
            items: result.richlist,
            lastPriceInXrp: result.lastPriceInXrp,
            isNFTProject: result.isNFTProject && sortType === "1",
            knownWallets:
              searchTerm.trim().length > 0
                ? result.searchHits
                : result.knownWallets,
            isEmpty: result.richlist.length === 0,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  componentDidMount() {
    this.getDataFromApi();
  }

  render() {
    const {
      error,
      isLoaded,
      isNFTProject,
      isEmpty,
      items,
      page,
      rowsPerPage,
      knownWallets,
      lastPriceInXrp,
    } = this.state;

    const handleChangePage = (event, newPage) => {
      this.setState({ lastPage: page, page: newPage }, () => {
        this.getDataFromApi();
      });
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState(
        {
          lastPage: -1,
          page: 0,
          rowsPerPage: parseInt(event.target.value, 10),
        },
        () => {
          this.getDataFromApi(true);
        },
      );
    };

    const handleSearch = (event) => {
      const searchTerm = event.target.value;
      this.setState({ lastPage: -1, page: 0, searchTerm: searchTerm }, () => {
        this.getDataFromApi(true);
      });
    };

    const isEmbedLayout = isEmbed(window.location.href);
    const applyBodyClassNonEmbed = isEmbedLayout ? "" : "main-body";

    const isWithPrice = lastPriceInXrp > 0 && !isNFTProject;
    const applyWideTableWithPrice = isWithPrice
      ? "table-container has-price"
      : "table-container";

    if (error) {
      return (
        <div className={applyBodyClassNonEmbed}>
          Error: {error.message}. The service seems down, please try again
          later.
        </div>
      );
    } else if (!isLoaded) {
      return <div className={applyBodyClassNonEmbed}>Loading...</div>;
    } else {
      return (
        <div className={applyBodyClassNonEmbed}>
          <Helmet>
            <title>{this.props.helmetPageTitle}</title>
            <meta
              name="description"
              content={this.props.helmetPageDescription}
            />
            {this.props.helmetLogoImage}
          </Helmet>
          <h1>{this.props.introHeading}</h1>
          <p className="text">{this.props.introText}</p>
          {this.props.logoImage}
          <TableContainer component={Paper} className={applyWideTableWithPrice}>
            <EnhancedTableToolbar handleSearch={handleSearch} />
            <Table
              sx={{ minWidth: 300, width: "100%" }}
              size="small"
              aria-label={this.props.tableAriaLabel}>
              <TableHead>
                <TableRow>
                  {!isEmpty && <StyledTableCell>Ranking</StyledTableCell>}

                  {isNFTProject && !isEmpty && (
                    <StyledTableCell align="left">NFTs</StyledTableCell>
                  )}
                  {isNFTProject && !isEmpty && (
                    <StyledTableCell align="left">Tokens</StyledTableCell>
                  )}
                  {!isNFTProject && !isEmpty && (
                    <StyledTableCell align="left">Holdings</StyledTableCell>
                  )}
                  {isWithPrice && !isEmpty && (
                    <StyledTableCell align="left">XRP Value</StyledTableCell>
                  )}
                  {!isEmpty && (
                    <StyledTableCell align="left">Wallet</StyledTableCell>
                  )}
                  {isEmpty && (
                    <StyledTableCell align="center">No Results</StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row) => (
                  <StyledTableRow
                    key={row.address}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCell component="th" scope="row">
                      {row.position}
                    </StyledTableCell>

                    {isNFTProject && (
                      <StyledTableCell align="left">
                        {row.nfTs > 0 ? (
                          <NumberFormat
                            value={parseFloat(row.nfTs.toFixed(0))}
                            displayType={"text"}
                            decimalScale="0"
                            thousandSeparator={true}
                          />
                        ) : (
                          "0"
                        )}
                      </StyledTableCell>
                    )}
                    {isNFTProject && (
                      <StyledTableCell align="left">
                        {row.amount >= 0.01 ? (
                          <NumberFormat
                            value={parseFloat(row.amount.toFixed(2))}
                            displayType={"text"}
                            decimalScale="2"
                            thousandSeparator={true}
                          />
                        ) : (
                          "0"
                        )}
                      </StyledTableCell>
                    )}
                    {!isNFTProject && (
                      <StyledTableCell align="left">
                        {row.amount >= 0.01 ? (
                          <NumberFormat
                            value={parseFloat(row.amount.toFixed(2))}
                            displayType={"text"}
                            decimalScale="2"
                            thousandSeparator={true}
                          />
                        ) : (
                          "<0.01"
                        )}
                      </StyledTableCell>
                    )}
                    {isWithPrice && (
                      <StyledTableCell align="left">
                        <NumberFormat
                          value={row.amount * lastPriceInXrp}
                          displayType={"text"}
                          decimalScale="2"
                          thousandSeparator={true}
                        />
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="left">
                      {row.address}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}

                {isEmpty && (
                  <TableRow>
                    <p className="">
                      Can't find your wallet? Make sure you have the project
                      trustline set.
                    </p>
                  </TableRow>
                )}

                <TableRow>
                  <TablePagination
                    count={parseInt(knownWallets)}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
  }
}

export default CustomTable;
