function setCookie(name, value) {
  document.cookie = name + "=" + value + ";max-age=31536000;path=/; Secure"; //Set the path while setting the Value.
}

function getCookie(name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(name + "=");
    if (c_start !== -1) {
      c_start = c_start + name.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end === -1) {
        c_end = document.cookie.length;
      }
      return document.cookie.substring(c_start, c_end);
    }
  }
  return "";
}

export { setCookie, getCookie };
