import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import NumberFormat from "react-number-format";
import isEmbed from "../../../utils/isEmbed.js";
import NFTMetaLoaderPlaceholder from "../../atoms/NFTMetaLoaderPlaceholder.js";
import { trackWindowScroll } from "react-lazy-load-image-component";

// The custom search bar
const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar>
      <TextField
        label="Search for your wallet"
        margin="normal"
        fullWidth
        id="search"
        type="search"
        placeholder="Search for your wallet"
        onChange={props.handleSearch}></TextField>
    </Toolbar>
  );
};

// Style the table heading
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#15202b",
    color: "#fff",
  },
}));

// Style the table odd rows
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#a9c0d6",
  },
}));

// The custom pagination bar
function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleOnInputPageChange = (event) => {
    let pageToBe = event.target.value - 1;
    // min is 0
    pageToBe = pageToBe < 1 ? 0 : pageToBe;

    // max is max
    let max = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
    pageToBe = pageToBe > max ? max : pageToBe;
    onPageChange(event, pageToBe);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <div className="table-page-container">
        <p className="table-page-label">Jump to page:</p>
        <input
          className="table-page-input"
          type="text"
          onChange={handleOnInputPageChange}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </div>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

class CustomNFTTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      isEmpty: false,
      items: [],
      page: 0,
      rowsPerPage: 100,
      knownWallets: 0,
      searchTerm: "",
      searchPage: "",
      imagePager: [],
    };
  }

  getDataFromApi() {
    let { page, rowsPerPage, searchTerm } = this.state;

    this.setState({ imagePager: new Array(rowsPerPage).fill(5) });

    // FE is zero indexed
    let bePage = page + 1;

    fetch(
      `https://love.richlist.xyz/api/getpaged?token=${this.props.tokenId}&page=${bePage}&take=${rowsPerPage}&term=${searchTerm}&sortType=${this.props.sortType}`,
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return this.setState({
            isLoaded: true,
            items: result.richlist,
            knownWallets:
              searchTerm.trim().length > 0
                ? result.searchHits
                : result.knownWallets,
            isEmpty: result.richlist.length === 0,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  componentDidMount() {
    this.getDataFromApi();
  }

  render() {
    const {
      error,
      isLoaded,
      isEmpty,
      items,
      page,
      rowsPerPage,
      knownWallets,
      imagePager,
    } = this.state;

    const handleChangePage = (event, newPage) => {
      this.setState({ lastPage: page, page: newPage }, () => {
        this.getDataFromApi();
      });
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState(
        {
          lastPage: -1,
          page: 0,
          rowsPerPage: parseInt(event.target.value, 10),
        },
        () => {
          this.getDataFromApi(true);
        },
      );
    };

    const handleSearch = (event) => {
      const searchTerm = event.target.value;
      this.setState({ lastPage: -1, page: 0, searchTerm: searchTerm }, () => {
        this.getDataFromApi(true);
      });
    };

    const isEmbedLayout = isEmbed(window.location.href);
    const applyBodyClassNonEmbed = isEmbedLayout ? "" : "main-body";

    if (error) {
      return (
        <div className={applyBodyClassNonEmbed}>
          Error: {error.message}. The service seems down, please try again
          later.
        </div>
      );
    } else if (!isLoaded) {
      return <div className={applyBodyClassNonEmbed}>Loading...</div>;
    } else {
      return (
        <div className={applyBodyClassNonEmbed}>
          <Helmet>
            <title>{this.props.helmetPageTitle}</title>
            <meta
              name="description"
              content={this.props.helmetPageDescription}
            />
            {this.props.helmetLogoImage}
          </Helmet>
          <h1>{this.props.introHeading}</h1>
          <p className="text">{this.props.introText}</p>
          {this.props.logoImage}
          <TableContainer component={Paper} className="table-container">
            <EnhancedTableToolbar handleSearch={handleSearch} />
            <Table
              sx={{ minWidth: 300, width: "100%" }}
              size="small"
              aria-label={this.props.tableAriaLabel}>
              <TableHead>
                <TableRow>
                  {!isEmpty && <StyledTableCell>Ranking</StyledTableCell>}

                  {!isEmpty && (
                    <StyledTableCell align="left">
                      {this.props.nftColName}
                    </StyledTableCell>
                  )}

                  {!isEmpty && (
                    <StyledTableCell align="left">NFTs</StyledTableCell>
                  )}

                  {!isEmpty && (
                    <StyledTableCell align="left">Wallet</StyledTableCell>
                  )}

                  {isEmpty && (
                    <StyledTableCell align="center">No Results</StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row, rowIndex) => (
                  <StyledTableRow
                    key={row.address}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <StyledTableCell component="th" scope="row">
                      {row.position}
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <NumberFormat
                        value={parseFloat(row.nfTs.toFixed(0))}
                        displayType={"text"}
                        decimalScale="0"
                        thousandSeparator={true}
                      />
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <div className="nft-image-row" key={Math.random()}>
                        {(() => {
                          if (
                            this.props.isOnXRP &&
                            row.address === this.props.nftIssuer
                          ) {
                            return <p>✨ To be minted ✨</p>;
                          } else {
                            if (row.nftDetails.length <= 5) {
                              return row.nftDetails.map((nftDetail) => (
                                <NFTMetaLoaderPlaceholder
                                  metaUrl={nftDetail.uri}
                                  key={nftDetail.nfTokenID}
                                  scrollPosition={this.scrollPosition}
                                  isOnXRP={this.props.isOnXRP}
                                  nfTokenID={nftDetail.nfTokenID}
                                  wallet={row.address}
                                />
                              ));
                            }
                            if (row.nftDetails.length > 5) {
                              return (
                                <>
                                  {row.nftDetails
                                    .slice(0, imagePager[rowIndex])
                                    .map((nftDetail) => (
                                      <NFTMetaLoaderPlaceholder
                                        metaUrl={nftDetail.uri}
                                        key={nftDetail.nfTokenID}
                                        scrollPosition={this.scrollPosition}
                                        isOnXRP={this.props.isOnXRP}
                                        nfTokenID={nftDetail.nfTokenID}
                                        wallet={row.address}
                                      />
                                    ))}
                                  {/* eslint-disable-next-line */}
                                  <a
                                    onClick={() => {
                                      imagePager[rowIndex] =
                                        imagePager[rowIndex] + 5;
                                      this.setState({ imagePager }, () => {});
                                    }}
                                    href="#"
                                    className="nft-more">
                                    more
                                  </a>
                                </>
                              );
                            }
                          }
                        })()}
                      </div>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      {this.props.walletLink && (
                        <a
                          href={this.props.walletLink + row.address}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Explore wallet">
                          {row.address}
                        </a>
                      )}

                      {!this.props.walletLink && row.address}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}

                {isEmpty && (
                  <TableRow>
                    <p className="">
                      Can't find your wallet? Make sure you have the token
                      project trustline set.
                    </p>
                  </TableRow>
                )}

                <TableRow>
                  <TablePagination
                    count={parseInt(knownWallets)}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
  }
}

export default trackWindowScroll(CustomNFTTable);
