import React, { Component } from "react";
import { Helmet } from "react-helmet";

class NotFound extends Component {
  render() {
    return (
      <div className="main-body">
        <Helmet>
          <title>richlist.xyz - Not Found</title>
          <meta name="description" content="404 Error page" />
        </Helmet>
        <h1>Not found</h1>
        <p>
          Either you have clicked on an invalid link, or this coin is not yet
          supported by the private richlist.xyz service.
        </p>
        <a href="/" className="">
          Back to home
        </a>
      </div>
    );
  }
}

export default NotFound;
