import React, { Component } from "react";
import isEmbed from "../../../utils/isEmbed.js";
import ThemeSwitch from "../../organisms/ThemeSwitch/ThemeSwitch.js";
import { slide as Menu } from "react-burger-menu";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNav1Open: false,
      isNav2Open: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", (event) => {
      if (
        event &&
        event.target &&
        typeof event.target.className == "string" &&
        !event.target.className.includes("nav-link") &&
        !event.target.className.includes("go-home")
      ) {
        this.setState({ isNavOpen: false, isNav2Open: false }, () => {});
      }
    });
  }

  render() {
    if (isEmbed(window.location.href)) {
      return null;
    }

    const toggleNav1 = (event) => {
      this.setState(
        {
          isNav1Open: !this.state.isNav1Open,
          isNav2Open: false,
        },
        () => {
          event.preventDefault();
        },
      );
    };

    const handleOnClose1 = (event) => {
      this.setState({ isNav1Open: false, isNav2Open: false }, () => {});
    };

    const toggleNav2 = (event) => {
      this.setState(
        {
          isNav2Open: !this.state.isNav2Open,
          isNav1Open: false,
        },
        () => {
          event.preventDefault();
        },
      );
    };

    const handleOnClose2 = (event) => {
      this.setState({ isNav1Open: false, isNav2Open: false }, () => {});
    };

    const showNotification = window.location.pathname !== "/explorer";

    return (
      <div>
        <header className="header">
          <span className="links">
            <a className="go-home" href="/">
              Home
            </a>
            <a className="" href="/explorer">
              Explorer
            </a>
            <button className="nav-trigger-link fake-link" onClick={toggleNav1}>
              Tokens
            </button>
            <button className="nav-trigger-link fake-link" onClick={toggleNav2}>
              NFTs
            </button>
            <a
              className="twitter-icon-link"
              href="https://twitter.com/ohonesix"
              target="_blank"
              rel="noopener noreferrer">
              <svg
                className="twitter-svg"
                width="35px"
                height="35px"
                viewBox="0 0 64.00 64.00"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="#ffffff"
                strokeWidth="1.7280000000000002">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#CCCCCC"
                  strokeWidth="0.256"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M56 16.46a67.49 67.49 0 0 0-8.11-.73 9.75 9.75 0 0 0-16.56 8.91c-8.08-.41-14.2-4.56-19.92-10.73-.78 1.52-2.26 6.37-1.11 9.87a19.1 19.1 0 0 0 6.75 8.59 26.05 26.05 0 0 1-8.52-1.85c1.41 4.63 6.25 11.35 13.86 12.4a19.46 19.46 0 0 1-12.07 4.19A19.66 19.66 0 0 1 8 47a27.42 27.42 0 0 0 14.9 4.39c17.87 0 27.65-14.89 27.65-27.81v-1.27z"></path>
                </g>
              </svg>
            </a>
          </span>
          <span className="theme-span">
            <ThemeSwitch />
          </span>
        </header>
        <Menu
          className="fly-menu"
          isOpen={this.state.isNav1Open}
          right
          width={"200px"}
          customBurgerIcon={false}
          onClose={handleOnClose1}>
          <a className="nav-link" href="/token/3dapes">
            3DAPES
          </a>
          <a className="nav-link" href="/token/arcx">
            ArcX
          </a>
          <a className="nav-link" href="/token/bafc">
            BAFC
          </a>
          <a className="nav-link" href="/token/thecityofgiezwa">
            CITYOFGIEZWA
          </a>
          <a className="nav-link" href="/token/giezwacoin">
            GiezwaCoin
          </a>
          <a className="nav-link" href="/token/ovo">
            OVO
          </a>
          <a className="nav-link" href="/token/projectgenesis">
            ProjectGenesis
          </a>
          <a className="nav-link" href="/token/sec">
            SEC
          </a>
          <a className="nav-link" href="/token/shibanft">
            ShibaNFT
          </a>
          <a className="nav-link" href="/token/swisstech">
            SwissTech
          </a>
          <a className="nav-link" href="/token/trsry">
            TRSRY
          </a>
          <a className="nav-link" href="/token/xloyality">
            XLoyalitY
          </a>
          <a className="nav-link" href="/token/xmagic">
            xMAGIC
          </a>
          <a className="nav-link" href="/token/xmalaya">
            xMalaya
          </a>
          <a className="nav-link" href="/token/xpizza">
            xPIZZA
          </a>
          <a className="nav-link" href="/token/xmg">
            XMG
          </a>
          <a className="nav-link" href="/token/xrdoge">
            XRdoge
          </a>
          <a className="nav-link" href="/token/xreefs">
            XReefs
          </a>
          <a className="nav-link" href="/token/xryo">
            xRYO
          </a>
          <a className="nav-link" href="/token/xstik">
            xSTIK
          </a>
          <a className="nav-link" href="/token/xtreme">
            xTREME
          </a>
          <a className="nav-link" href="/token/xwar">
            XWAR
          </a>
          <div className="menu-upsell">
            More coming soon{" "}
            <span role="img" aria-label="flying money emoji">
              💸
            </span>
          </div>
        </Menu>

        <Menu
          className="fly-menu"
          isOpen={this.state.isNav2Open}
          right
          width={"200px"}
          customBurgerIcon={false}
          onClose={handleOnClose2}>
          <a className="nav-link" href="/nft/xpizza">
            xPIZZA
          </a>
          <a className="nav-link" href="/nft/xprymalz">
            xPrymalz
          </a>
          <a className="nav-link" href="/nft/xrdoge">
            XRdoge
          </a>
          <a className="nav-link" href="/nft/xreefs">
            XReefs
          </a>
          <div className="menu-upsell">
            More coming soon{" "}
            <span role="img" aria-label="artist easel emoji">
              🎨
            </span>
          </div>
        </Menu>

        {showNotification && (
          <div className="notification">
            <p className="notification-text">
              Try our new <a href="/explorer">wallet NFT explorer</a>
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default Header;
