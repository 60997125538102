const isEmbed = (url) => {
  if (url.toLowerCase().endsWith("embed")) {
    return true;
  }

  if (url.toLowerCase().includes("localhost:3000/xstik")) {
    return true;
  }

  if (url.toLowerCase().includes("richlist.xyz/xstik")) {
    return true;
  }

  if (url.toLowerCase().includes("token")) {
    return false;
  }

  return false;
};

export default isEmbed;
