import React, { Component } from "react";
import CustomNFTTable from "../../organisms/CustomNFTTable/CustomNFTTable.js";

class nxREEFS extends Component {
  render() {
    return (
      <CustomNFTTable
        tokenId="5852656566730000000000000000000000000000"
        helmetPageTitle="XReefs NFT Richlist"
        helmetPageDescription="Richlist for XReefs NFTs on the XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/XReefs.png" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/XReefs.png"
              alt="XReefs logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            XReefs NFT Richlist
          </span>
        }
        introText={
          <span>
            Supporting marine-life conservation with NFTs,{" "}
            <a href="https://linktr.ee/RippleReefs">take part</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="XReefs NFT holders"
        sortType="2"
        nftColName="Owned"
        isOnXRP={true}
        nftIssuer="rNPEjBY4wcHyccfWjDpuAgpxk8S2itLNiH"
      />
    );
  }
}

export default nxREEFS;
