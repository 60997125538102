import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xTREME extends Component {
  render() {
    return (
      <CustomTable
        tokenId="785452454D450000000000000000000000000000"
        helmetPageTitle="xTREME Richlist"
        helmetPageDescription="Richlist for the xTREME coin on XRPL"
        introHeading={<span>xTREME Richlist</span>}
        introText={<span>xTREME NFT launchpad.</span>}
        logoImage=""
        tableAriaLabel="xTREME holders"
      />
    );
  }
}

export default xTREME;
