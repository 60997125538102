import React, { Component } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Lightbox } from "react-modal-image";

class NFTMetaLoaderPlaceholder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      metaUrl: this.props.metaUrl,
      isOnXRP: this.props.isOnXRP,
      nfTokenID: this.props.nfTokenID,
      wallet: this.props.wallet,
      imageUrl: null,
      ipfsUrl: null,
      imageName: null,
      lightBoxOpen: false,
    };
  }

  GetNFTMetaDataFromUrl() {
    let { nfTokenID, wallet } = this.state;

    const universalNFTUrl = `https://api.universalnft.dev/v1.0/NFT?NFTokenID=${nfTokenID}&OwnerWalletAddress=${wallet}`;

    fetch(universalNFTUrl)
      .then((res) => res.json())
      .then((result) => {
        return this.setState({
          ipfsUrl: result.imageUrl,
          imageUrl: result.imageThumbnailCacheUrl,
          imageName: result.nfTokenID,
        });
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  componentDidMount() {
    this.GetNFTMetaDataFromUrl();
  }

  render() {
    let { imageUrl, imageName, lightBoxOpen, ipfsUrl } = this.state;

    const handleOpenLightBox = (event) => {
      this.setState({ lightBoxOpen: true }, () => {});
    };

    const handleCloseLightBox = (event) => {
      this.setState({ lightBoxOpen: false }, () => {});
    };

    const handleLoadError = (event) => {
      this.setState({ imageUrl: ipfsUrl });
    };

    return (
      <div>
        {lightBoxOpen && (
          <Lightbox
            small={imageUrl}
            large={ipfsUrl}
            hideDownload="true"
            hideZoom="true"
            onClose={handleCloseLightBox}
          />
        )}
        {imageUrl && (
          <LazyLoadImage
            alt={imageName}
            height={150}
            src={imageUrl} // use normal <img> attributes as props
            width={150}
            className="nft-image"
            title={imageName}
            scrollPosition={this.props.scrollPosition}
            onClick={handleOpenLightBox}
            onError={handleLoadError}
          />
        )}

        {!imageUrl && <div className="spinner"></div>}
      </div>
    );
  }
}

export default NFTMetaLoaderPlaceholder;
