import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xXMG extends Component {
  render() {
    return (
      <CustomTable
        tokenId="XMG"
        helmetPageTitle="XMG Richlist"
        helmetPageDescription="Richlist for XMG on the XRPL"
        helmetLogoImage={<link rel="icon" href="/img/XMG.png" sizes="32x32" />}
        introHeading={
          <span>
            <img
              src="/img/XMG.png"
              alt="XMG logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            XMG Richlist
          </span>
        }
        introText={
          <span>
            Community-based gaming and trading platform,{" "}
            <a href="https://linktr.ee/xmgxrp">read more</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="XMG holders"
      />
    );
  }
}

export default xXMG;
