import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xRDOGE extends Component {
  render() {
    return (
      <CustomTable
        tokenId="5852646F67650000000000000000000000000000"
        helmetPageTitle="XRdoge Richlist"
        helmetPageDescription="Richlist for XRdoge on the XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/XRdoge.png" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/XRdoge.png"
              alt="XRdoge logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            XRdoge Richlist
          </span>
        }
        introText={
          <div>
            UNLEASH THE MEMETIC POWER OF{" "}
            <a href="https://linktr.ee/xrdoge.lab">XRDOGE</a>.
            <p className="text">
              Check out the <a href="/nft/xrdoge">NFT richlist here</a>.
            </p>
          </div>
        }
        logoImage=""
        tableAriaLabel="XRdoge holders"
        sortType="0"
      />
    );
  }
}

export default xRDOGE;
