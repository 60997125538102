import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xBAFC extends Component {
  render() {
    return (
      <CustomTable
        tokenId="4241464300000000000000000000000000000000"
        helmetPageTitle="BAFC Richlist"
        helmetPageDescription="Richlist for BAFC on the XRPL"
        helmetLogoImage={<link rel="icon" href="/img/BAFC.png" sizes="32x32" />}
        introHeading={
          <span>
            <img
              src="/img/BAFC.png"
              alt="BAFC logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            BAFC Richlist
          </span>
        }
        introText={
          <span>
            The first football community DAO,{" "}
            <a href="https://linktr.ee/theboredapefootballclub">sign up</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="BAFC holders"
      />
    );
  }
}

export default xBAFC;
