import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xOVO extends Component {
  render() {
    return (
      <CustomTable
        tokenId="OVO"
        helmetPageTitle="OVO Richlist"
        helmetPageDescription="Richlist for the OVO token on the XRPL"
        helmetLogoImage={<link rel="icon" href="/img/OVO.jpg" sizes="32x32" />}
        introHeading={
          <span>
            <img
              src="/img/OVO.jpg"
              alt="OVO logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            OVO Richlist
          </span>
        }
        introText={
          <span>
            Opening Virtual Ownership on the XRPL,{" "}
            <a href="https://linktr.ee/openegg">read more</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="OVO holders"
      />
    );
  }
}

export default xOVO;
