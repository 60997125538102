const isHome = (url) => {
  if (url.toLowerCase().endsWith("richlist.xyz/")) {
    return true;
  }

  if (url.toLowerCase().endsWith("localhost:3000/")) {
    return true;
  }

  if (url.toLowerCase().endsWith("richlist.xyz")) {
    return true;
  }

  if (url.toLowerCase().endsWith("localhost:3000")) {
    return true;
  }

  return false;
};

export default isHome;
