import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xLOYALITY extends Component {
  render() {
    return (
      <CustomTable
        tokenId="584C6F79616C6974590000000000000000000000"
        helmetPageTitle="XLoyalitY Richlist"
        helmetPageDescription="Richlist for XLoyalitY on the XRPL"
        helmetLogoImage=""
        introHeading={
          <span>
            <span role="img" aria-label="diamond emoji">
              💎
            </span>{" "}
            XLoyalitY Richlist
          </span>
        }
        introText={
          <span>
            NFT's and weekly rewards for holders{" "}
            <a href="https://linktr.ee/xloyality">check it out</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="XLoyalitY holders"
      />
    );
  }
}

export default xLOYALITY;
