import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xMAGIC extends Component {
  render() {
    return (
      <CustomTable
        tokenId="784D414749430000000000000000000000000000"
        helmetPageTitle="xMAGIC Richlist"
        helmetPageDescription="Richlist for xMAGIC on the XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/xMAGIC.jpg" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/xMAGIC.jpg"
              alt="xMAGIC logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            xMAGIC Richlist
          </span>
        }
        introText={
          <span>
            The future of the reflection token ecosystem,{" "}
            <a href="https://linktr.ee/magical_finance">read more</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="xMAGIC holders"
      />
    );
  }
}

export default xMAGIC;
