import React, { Component } from "react";

class Home extends Component {
  render() {
    return (
      <div className="main-body">
        <h1>
          Welcome{" "}
          <span role="img" aria-label="wave emoji" className="wave-hi">
            👋
          </span>
        </h1>
        <p className="text">
          This premium richlist service is meant for XRP projects that have
          large amounts of trustlines. For smaller projects there are other free
          richlists available.
        </p>
        <p className="text">
          XLS-20 NFTs are now supported! Project admins please get in touch with
          details to have your richlist switch over.
        </p>
        <p className="text">
          Token prices are from Sologenic and updated every 15 minutes.
        </p>
        <p className="text">
          Feel free to{" "}
          <a
            href="https://twitter.com/jacob_pretorius"
            target="_blank"
            rel="noopener noreferrer">
            contact me
          </a>{" "}
          if you would like to have your coin use this richlist.
        </p>

        <p className="text accent-text">
          Something look wrong? Check the{" "}
          <a
            href="https://stats.uptimerobot.com/JkWYtBjKw"
            target="_blank"
            rel="noopener noreferrer">
            service status page
          </a>
          .
        </p>
        {/* <p className="text accent-text">
          Private richlist.xyz Ripple validator{" "}
          <a
            href="https://livenet.xrpl.org/network/validators"
            target="_blank"
            rel="noopener noreferrer">
            operational
          </a>
          .
        </p> */}

        <p className="text accent-text">
          Disclaimer: All information is provided for research purposes only and
          is in no way an endorsement of any project, token, or team.
        </p>
      </div>
    );
  }
}

export default Home;
