import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xRYO extends Component {
  render() {
    return (
      <CustomTable
        tokenId="7852594F00000000000000000000000000000000"
        helmetPageTitle="xRYO Richlist"
        helmetPageDescription="Richlist for the xRYO token on XRPL"
        helmetLogoImage={<link rel="icon" href="/img/xRYO.png" sizes="32x32" />}
        introHeading={
          <span>
            <img
              src="/img/xRYO.png"
              alt="xRYO logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            xRYO Richlist
          </span>
        }
        introText={
          <span>
            xRYO is a part of the community based project xRONIN,{" "}
            <a href="https://linktr.ee/xronin">check it out</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="xRYO holders"
      />
    );
  }
}

export default xRYO;
