import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xPIZZA extends Component {
  render() {
    return (
      <CustomTable
        tokenId="7850697A7A610000000000000000000000000000"
        helmetPageTitle="xPizza Richlist"
        helmetPageDescription="Richlist for the xPizza coin on XRPL"
        introHeading={
          <span>
            <span role="img" aria-label="pizza slice emoji">
              🍕
            </span>{" "}
            xPizza Richlist
          </span>
        }
        introText={<span>xPizza served up hot and fresh.</span>}
        logoImage=""
        tableAriaLabel="xPizza holders"
        sortType="1"
      />
    );
  }
}

export default xPIZZA;
