import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xTHECITYOFGIEZWA extends Component {
  render() {
    return (
      <CustomTable
        tokenId="544845434954594F464749455A57410000000000"
        helmetPageTitle="THECITYOFGIEZWA Richlist"
        helmetPageDescription="Richlist for THECITYOFGIEZWA on the XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/TheCityOfGiezwa.jpg" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/TheCityOfGiezwa.jpg"
              alt="THECITYOFGIEZWA logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            THECITYOFGIEZWA Richlist
          </span>
        }
        introText={
          <span>
            Welcome to the future of 100% sustainability,{" "}
            <a href="https://giezwacoin.com/the-future">check it out</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="THECITYOFGIEZWA holders"
      />
    );
  }
}

export default xTHECITYOFGIEZWA;
