import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";
class xTRSRY extends Component {
  render() {
    return (
      <CustomTable
        tokenId="5452535259000000000000000000000000000000"
        helmetPageTitle="TRSRY Richlist"
        helmetPageDescription="Richlist for the TRSRY coin on XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/TRSRY_400x400.jpg" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/TRSRY_400x400.jpg"
              alt="TRSRY logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            TRSRY Richlist
          </span>
        }
        introText={
          <span>
            Bringing greater utility to the XRPL,{" "}
            <a href="https://linkfly.to/TRSRYxrpl">check it out</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="TRSRY holders"
      />
    );
  }
}

export default xTRSRY;
