import XSTICK from "../xSTIK/xSTIK.js";
import React, { Component } from "react";

import "../xSTIK/embed.css";
class xSTIKEmbed extends Component {
  render() {
    return (
      <div className="embed-xstik">
        <XSTICK
          introTextOverride={
            <span>
              xSTIK - The XRPL token to hold <br></br>
              <a href="https://www.xrpstik.com/">xrpstik.com</a>
            </span>
          }
          logoImageOverride={
            <a href="https://www.xrpstik.com/" className="no-hover">
              <img
                src="/img/xStikGold.png"
                alt="xSTICK logo"
                height="250"></img>
            </a>
          }
        />
      </div>
    );
  }
}

export default xSTIKEmbed;
