import React, { Component } from "react";
import isEmbed from "../../../utils/isEmbed.js";
import isHome from "../../../utils/isHome.js";

class Footer extends Component {
  render() {
    if (isEmbed(window.location.href) || isHome(window.location.href)) {
      return null;
    }

    return (
      <div className="footer">
        <p className="text accent-text">
          Follow{" "}
          <a
            href="https://twitter.com/ohonesix"
            target="_blank"
            rel="noopener noreferrer">
            ohonesix on Twitter
          </a>{" "}
          to stay up to date with our XRPL work.
        </p>
      </div>
    );
  }
}

export default Footer;
