import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xShibaNFT extends Component {
  render() {
    return (
      <CustomTable
        tokenId="53686962614E4654000000000000000000000000"
        helmetPageTitle="ShibaNFT Richlist"
        helmetPageDescription="Richlist for the ShibaNFT coin on XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/ShibaNFT_400x400.jpg" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/ShibaNFT_400x400.jpg"
              alt="ShibaNFT logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            ShibaNFT Richlist
          </span>
        }
        introText={
          <span>
            A game changer in the NFT/METAVERSE space,{" "}
            <a href="https://linktr.ee/ShibaNFT">check it out</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="ShibaNFT holders"
      />
    );
  }
}

export default xShibaNFT;
