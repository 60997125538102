import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xMalaya extends Component {
  render() {
    return (
      <CustomTable
        tokenId="784D616C61796100000000000000000000000000"
        helmetPageTitle="xMalaya Richlist"
        helmetPageDescription="Richlist for the xMalaya token on the XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/xMalaya.png" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/xMalaya.png"
              alt="xMalaya logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            xMalaya Richlist
          </span>
        }
        introText={
          <span>
            A vision to diversify income,{" "}
            <a href="https://linktr.ee/malayainfinity">read more</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="xMalaya holders"
      />
    );
  }
}

export default xMalaya;
