import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xGIEZWACOIN extends Component {
  render() {
    return (
      <CustomTable
        tokenId="4769657A7761436F696E00000000000000000000"
        helmetPageTitle="GiezwaCoin Richlist"
        helmetPageDescription="Richlist for GiezwaCoin on the XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/GiezwaCoin.png" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/GiezwaCoin.png"
              alt="GiezwaCoin logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            GiezwaCoin Richlist
          </span>
        }
        introText={
          <span>
            A utility coin to help make the human experience better,{" "}
            <a href="https://beacons.ai/giezwacoin">check it out</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="GiezwaCoin holders"
      />
    );
  }
}

export default xGIEZWACOIN;
