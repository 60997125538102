import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xPROJECTGENESIS extends Component {
  render() {
    return (
      <CustomTable
        tokenId="GEN"
        helmetPageTitle="Project Genesis Richlist"
        helmetPageDescription="Richlist for Project Genesis on the XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/ProjectGenesis.jpg" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/ProjectGenesis.jpg"
              alt="Project Genesis logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            Project Genesis Richlist
          </span>
        }
        introText={
          <span>
            A project revolving around partnerships and community,{" "}
            <a href="https://linktr.ee/Project_Genesis">find out more</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="Project Genesis holders"
      />
    );
  }
}

export default xPROJECTGENESIS;
