import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xArcX extends Component {
  render() {
    return (
      <CustomTable
        tokenId="4172635800000000000000000000000000000000"
        helmetPageTitle="ArcX Richlist"
        helmetPageDescription="Richlist for ArcX on the XRPL"
        helmetLogoImage={<link rel="icon" href="/img/ArcX.png" sizes="32x32" />}
        introHeading={
          <span>
            <img
              src="/img/ArcX.png"
              alt="ArcX logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            ArcX Richlist
          </span>
        }
        introText={
          <span>
            Planning to be the Nr.1 arcade on the XRPL,{" "}
            <a href="https://linktr.ee/arcadexrpl">join the fun</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="ArcX holders"
      />
    );
  }
}

export default xArcX;
