import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from "./Components/organisms/Header/Header.js";
import Footer from "./Components/organisms/Footer/Footer.js";
import Home from "./Components/pages/Home/Home.js";
import NotFound from "./Components/pages/NotFound/NotFound.js";
import WalletExplorer from "./Components/pages/WalletExplorer/WalletExplorer.js";
import xSTIK from "./Components/pages/xSTIK/xSTIK.js";
import xSTIKEmbed from "./Components/pages/xSTIK/embed.js";

import xPIZZA from "./Components/pages/xPIZZA/xPIZZA.js";
import xRPL3DAPES from "./Components/pages/xRPL3DAPES/xRPL3DAPES.js";
import xTRSRY from "./Components/pages/xTRSRY/xTRSRY.js";
import xShibaNFT from "./Components/pages/xShibaNFT/xShibaNFT.js";
import xREEFS from "./Components/pages/xREEFS/xREEFS.js";
import xTREME from "./Components/pages/xTREME/xTREME.js";
import xRDOGE from "./Components/pages/xRDOGE/xRDOGE.js";
import xLOYALITY from "./Components/pages/xLOYALITY/xLOYALITY.js";
import xSWISSTECH from "./Components/pages/xSWISSTECH/xSWISSTECH.js";
import xGIEZWACOIN from "./Components/pages/xGIEZWACOIN/xGIEZWACOIN.js";
import xPROJECTGENESIS from "./Components/pages/xPROJECTGENESIS/xPROJECTGENESIS.js";
import xWAR from "./Components/pages/xWAR/xWAR.js";
import xXMG from "./Components/pages/xXMG/xXMG.js";
import xBAFC from "./Components/pages/xBAFC/xBAFC.js";
import xSEC from "./Components/pages/xSEC/xSEC.js";
import xMAGIC from "./Components/pages/xMAGIC/xMAGIC.js";
import xArcX from "./Components/pages/xArcX/xArcX.js";
import xTHECITYOFGIEZWA from "./Components/pages/xTHECITYOFGIEZWA/xTHECITYOFGIEZWA.js";
import xRYO from "./Components/pages/xRYO/xRYO.js";
import xMalaya from "./Components/pages/xMalaya/xMalaya.js";
import xOVO from "./Components/pages/xOVO/xOVO.js";

import nxPIZZA from "./Components/pages/nxPIZZA/nxPIZZA.js";
import nxRDOGE from "./Components/pages/nxRDOGE/nxRDOGE.js";
import nxREEFS from "./Components/pages/nxREEFS/nxREEFS.js";
import nxPRYMALZ from "./Components/pages/nxPRYMALZ/nxPRYMALZ.js";

import "./App.css";
import "./Dark.css";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/explorer" component={WalletExplorer} />
        <Route exact path="/token/xstik" component={xSTIK} />
        <Route
          exact
          path="/token/785354494B000000000000000000000000000000"
          component={xSTIK}
        />
        <Route exact path="/xstik" component={xSTIKEmbed} />
        <Route
          exact
          path="/785354494B000000000000000000000000000000"
          component={xSTIKEmbed}
        />
        <Route exact path="/xstik/embed" component={xSTIKEmbed} />
        <Route exact path="/token/trsry" component={xTRSRY} />
        <Route exact path="/token/shibanft" component={xShibaNFT} />
        <Route exact path="/token/xpizza" component={xPIZZA} />
        <Route exact path="/token/xrpl3dapes" component={xRPL3DAPES} />
        <Route exact path="/token/3dapes" component={xRPL3DAPES} />
        <Route exact path="/token/xreefs" component={xREEFS} />
        <Route exact path="/token/xtreme" component={xTREME} />
        <Route exact path="/token/xrdoge" component={xRDOGE} />
        <Route exact path="/token/xloyality" component={xLOYALITY} />
        <Route exact path="/token/swisstech" component={xSWISSTECH} />
        <Route exact path="/token/giezwacoin" component={xGIEZWACOIN} />
        <Route exact path="/token/projectgenesis" component={xPROJECTGENESIS} />
        <Route exact path="/token/xwar" component={xWAR} />
        <Route exact path="/token/xmg" component={xXMG} />
        <Route exact path="/token/bafc" component={xBAFC} />
        <Route exact path="/token/sec" component={xSEC} />
        <Route exact path="/token/xmagic" component={xMAGIC} />
        <Route exact path="/token/arcx" component={xArcX} />
        <Route exact path="/token/xryo" component={xRYO} />
        <Route exact path="/token/xmalaya" component={xMalaya} />
        <Route exact path="/token/ovo" component={xOVO} />
        <Route
          exact
          path="/token/thecityofgiezwa"
          component={xTHECITYOFGIEZWA}
        />
        <Route exact path="/token/cityofgiezwa" component={xTHECITYOFGIEZWA} />

        <Route exact path="/nft/xpizza" component={nxPIZZA} />
        <Route exact path="/nft/xrdoge" component={nxRDOGE} />
        <Route exact path="/nft/xreefs" component={nxREEFS} />
        <Route exact path="/nft/xprymalz" component={nxPRYMALZ} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
