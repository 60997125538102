import React, { useState, useEffect, useMemo, Component } from "react";
import useMeasure from "react-use-measure";
import { useTransition, a } from "@react-spring/web";
import shuffle from "lodash.shuffle";
import styles from "./styles.module.css";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet";

// from https://codesandbox.io/p/sandbox/masonry-grid-mdovb?file=%2Fsrc%2FApp.tsx%3A56%2C32

function useMedia(queries, values, defaultValue) {
  const match = () =>
    values[queries.findIndex((q) => matchMedia(q).matches)] || defaultValue;
  const [value, set] = useState(match);
  useEffect(() => {
    const handler = () => set(match);
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  }, []);
  return value;
}

function Masonry(props) {
  // Hook1: Tie media queries to the number of columns
  const columns = useMedia(
    ["(min-width: 1200px)", "(min-width: 800px)", "(min-width: 600px)"],
    [6, 4, 3],
    3,
  );
  // Hook2: Measure the width of the container element
  const [ref, { width }] = useMeasure();
  // Hook3: Hold items
  const [items, set] = useState(props.items);
  // Hook4: shuffle data every 2 seconds
  useEffect(() => {
    const t = setInterval(() => set(shuffle), 5000);
    return () => clearInterval(t);
  }, []);
  // Hook5: Form a grid of stacked items using width & columns we got from hooks 1 & 2
  const [heights, gridItems] = useMemo(() => {
    let heights = new Array(columns).fill(0); // Each column gets a height starting with zero
    let size = 150;
    if (columns > 3) size = 250;
    let gridItems = items.map((child, i) => {
      const column = heights.indexOf(Math.min(...heights)); // Basic masonry-grid placing, puts tile into the smallest column using Math.min
      const x = (width / columns) * column; // x = container width / number of columns * column index,
      const y = (heights[column] += size) - size; // y = it's just the height of the current column

      return {
        ...child,
        x,
        y,
        width: width / columns,
        height: size,
      };
    });
    return [heights, gridItems];
  }, [columns, items, width]);
  // Hook6: Turn the static grid values into animated transitions, any addition, removal or change will be animated
  const transitions = useTransition(gridItems, {
    key: (item) => item.imageThumbnailCacheUrl,
    from: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 0 }),
    enter: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 1 }),
    update: ({ x, y, width, height }) => ({ x, y, width, height }),
    leave: { height: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25,
  });
  // Render the grid
  return (
    <div
      ref={ref}
      className={styles.list}
      style={{ height: Math.max(...heights) }}>
      {transitions((style, item) => (
        <a.div style={style}>
          <div
            style={{
              backgroundImage: `url(${item.imageThumbnailCacheUrl})`,
            }}
          />
        </a.div>
      ))}
    </div>
  );
}

class WalletExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLoaded: false,
      error: false,
      walletAddress: "",
    };
  }

  getDataFromApi() {
    let { walletAddress } = this.state;

    fetch(
      `https://api.universalnft.dev/v1.0/Wallet?OwnerWalletAddress=${walletAddress}`,
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result.length);
          return this.setState({
            loading: false,
            isLoaded: true,
            items: result.length > 0 ? result : null,
            error: result?.length === 0,
          });
        },
        (error) => {
          console.log(error);
          this.setState({
            loading: false,
            isLoaded: true,
            error: true,
            items: null,
          });
        },
      );
  }

  render() {
    const { isLoaded, items, error, loading } = this.state;

    const handleSearch = (event) => {
      const searchTerm = event.target.value;
      this.setState(
        { walletAddress: searchTerm, loading: true, items: null, error: false },
        () => {
          this.getDataFromApi(true);
        },
      );
    };

    return (
      <div>
        <Helmet>
          <title>Wallet NFT Explorer</title>
          <meta name="description" content="Show all NFTs in a XRPL wallet" />
        </Helmet>
        <Toolbar>
          <TextField
            label="Search for a wallet address"
            margin="normal"
            fullWidth
            id="search"
            type="search"
            placeholder="Search for a wallet address"
            onChange={handleSearch}></TextField>
        </Toolbar>
        {loading && (
          <>
            <div className="center-loader">
              <CircularProgress />
            </div>
            <p className="text">
              Please be patient while we search the blockchain
            </p>
          </>
        )}
        {isLoaded && !error && items != null && <Masonry items={items} />}
        {isLoaded && error && (
          <div>
            <p className="text center-loader">
              Could not load NFTs for that wallet, does it have any?
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default WalletExplorer;
