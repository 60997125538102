import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xREEFS extends Component {
  render() {
    return (
      <CustomTable
        tokenId="5852656566730000000000000000000000000000"
        helmetPageTitle="XReefs Richlist"
        helmetPageDescription="Richlist for the XReefs coin on XRPL"
        helmetLogoImage={
          <link rel="icon" href="/img/XReefs.png" sizes="32x32" />
        }
        introHeading={
          <span>
            <img
              src="/img/XReefs.png"
              alt="XReefs logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            XReefs Richlist
          </span>
        }
        introText={
          <span>
            Supporting marine-life conservation with NFTs,{" "}
            <a href="https://linktr.ee/RippleReefs">take part</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="XReefs holders"
        sortType="1"
      />
    );
  }
}

export default xREEFS;
