import React, { Component } from "react";
import CustomTable from "../../organisms/CustomTable/CustomTable.js";

class xSEC extends Component {
  render() {
    return (
      <CustomTable
        tokenId="SEC"
        helmetPageTitle="SEC Richlist"
        helmetPageDescription="Richlist for SEC on the XRPL"
        helmetLogoImage={<link rel="icon" href="/img/SEC.jpg" sizes="32x32" />}
        introHeading={
          <span>
            <img
              src="/img/SEC.jpg"
              alt="SEC logo"
              className="coin-logo"
              width="30"
              height="30"></img>
            SEC Richlist
          </span>
        }
        introText={
          <span>
            The first protest token on the XRP ledger,{" "}
            <a href="https://linktr.ee/SuperEagleCoin">learn more</a>.
          </span>
        }
        logoImage=""
        tableAriaLabel="SEC holders"
      />
    );
  }
}

export default xSEC;
